import Vue from 'vue';
import Router from 'vue-router';

import store from './store';
import mixinWhiteLabel from './mixins';
import globalTypes from '@/store/types/global';
import authTypes from '@/store/types/auth';

Vue.use(Router);

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/',
      name: 'inicio',
      meta: {
        secure: false
      },
      component: () => import('./views/Select.vue')
    },
    {
      path: '/null',
      redirect: '/'
    },
    {
      path: '/select',
      name: 'select',
      meta: {
        secure: false
      },
      component: () => import('./views/Select.vue')
    },
    {
      path: '/branch',
      name: 'branch',
      meta: {
        secure: false
      },
      component: () => import('./views/Branch.vue')
    },
    {
      path: '/events',
      name: 'events',
      meta: {
        secure: false
      },
      component: () => import('./views/Events.vue')
    },
    {
      path: '/events/:id',
      name: 'eventid',
      meta: {
        secure: false
      },
      component: () => import('./views/EventId.vue')
    },
    {
      path: '/checkout',
      name: 'checkout',
      meta: {
        secure: true
      },
      component: () => import('./views/Checkout.vue')
    },
    {
      path: '/confirmation',
      name: 'confirmation',
      meta: {
        secure: false
      },
      component: () => import('./views/Confirmation.vue')
    },
    {
      path: '/account',
      name: 'account',
      meta: {
        secure: true
      },
      component: () => import('./views/Account.vue')
    },
    {
      path: '/poll',
      name: 'poll',
      meta: {
        secure: false
      },
      component: () => import('./views/Poll.vue')
    },
    {
      path: '/reservation',
      name: 'reservation',
      meta: {
        secure: false
      },
      component: () => import('./views/Reservation.vue')
    },
    {
      path: '/maintenance',
      name: 'maintenance',
      meta: {
        secure: false
      },
      component: () => import('./views/Maintenance.vue')
    },
    {
      path: '/giftcard',
      name: 'giftcard',
      meta: {
        secure: false
      },
      component: () => import('./views/GiftCard.vue')
    },
    {
      path: '/error-404',
      name: 'error-404',
      meta: {
        secure: false
      },
      component: () => import('./views/Error404.vue')
    },
    {
      path: '/pollQR',
      name: 'pollQR',
      meta: {
        secure: false
      },
      component: () => import('./views/PollQR.vue')
    },
    {
      path: '/menuDigital',
      name: 'menuDigital',
      meta: {
        secure: false
      },
      component: () => import('./views/menuDigital.vue')
    },
    {
      path: '/prepago/:id',
      name: 'prepago',
      meta: {
        secure: false
      },
      component: () => import('./views/Prepago.vue')
    },
    {
      path: '/admin',
      name: 'admin',
      meta: {
        secure: false
      }
    },
    {
      path: '/domicilios',
      name: 'domicilios',
      meta: {
        secure: false
      }
    },
    {
      path: '/unsubscribe/:token',
      name: 'unsubscribe',
      meta: {
        secure: false
      },
      component: () => import('./views/UnSubscribe.vue')
    }
  ]
});
const privates = [
  'checkout'
];
router.beforeEach((to, from, next) => {
  let vendorUrl = mixinWhiteLabel.methods.subDomain();
  store.commit(globalTypes.mutations.setVendorUrl, vendorUrl);
  store.commit(authTypes.mutations.setUser);
  store.commit(authTypes.mutations.setUserId);
  store.commit(globalTypes.mutations.setEmbedSystem, false);
  const params = to.query;
  if (Object.keys(params).length > 0 && params.embed) {
    store.commit(globalTypes.mutations.setEmbedSystem, true);
  }
  store.dispatch({ type: 'whiteLabel:vendorId', data: { url: vendorUrl } }).then((response) => {
    if (response.data.code === 403) {
      next({ path: '/maintenance' });
    }
    store.dispatch({
      type: 'whiteLabel:getIntegrationDefaults',
      data: {
        filter: 'front'
      }
    }).finally(() => {
      store.dispatch({
        type: 'whiteLabel:getIntegrations',
        data: { vendorId: mixinWhiteLabel.methods.decryptMx(response.data.vendorId) }
      }).finally(() => {
        store.dispatch({ type: 'whiteLabel:vendor', data: { vendorId: response.data.vendorId } }).then(data => {
          document.title = data.data.vendor.displayName;
          document.querySelector("link[rel*='icon']").href = (data.data.vendor.photoURL !== null) ? data.data.vendor.photoURL : data.data.vendor.thumbnailURL;
          if (to.path === '/maintenance') {
            data.data.vendor.isActive = 0;
            document.title = 'Mantenimiento';
          }
          if (to.path === '/admin') {
            if (data.data.vendor.marketplaceId != null) {
              window.location.href = 'https://admindomicilios.precompro.com/login';
            } else {
              next('/error-404');
            }
          }
          if (to.path === '/domicilios') {
            if (data.data.vendor.marketplaceId != null) {
              window.location.href = 'https://domicilios.precompro.com/' + data.data.vendor.marketplaceId + '/restaurant';
            } else {
              next('/error-404');
            }
          }
          if (!data.data.vendor.isActive && to.name !== 'maintenance') {
            next({ path: '/maintenance' });
          }
          if (data.data.vendor.isActive && to.name === 'maintenance') {
            next({ path: '/select' });
          }
          if (to.meta.secure && typeof window.localStorage.getItem('_user') === 'undefined') {
            if (
              privates.includes(to.name) &&
              (!window.localStorage.getItem('_reservation') && !window.localStorage.getItem('_reservation_link'))
            ) {
              next({ path: '/select' });
            }
            next({ path: '/select' });
          } else {
            if (!to.matched.length) {
              next('/error-404');
            } else {
              if (
                privates.includes(to.name) &&
                (!window.localStorage.getItem('_reservation') && !window.localStorage.getItem('_reservation_link'))
              ) {
                next({ path: '/select' });
              }
              next();
            }
          }
        });
      });
    });
  });
});

export default router;
