import namespace from '@/helpers/namespace';

export default namespace('poll', {
  getters: [
    'poll',
    'pollInformation'
  ],
  actions: [
    'encuesta',
    'sendAnswer',
    'sendAnswerUserPoll',
    'pollVendor'
  ],
  mutations: [
    'setPoll',
    'setPollInformation'
  ]
});
