import moment from 'moment';
export const getYearsPayment = {
  install (Vue, options) {
    Vue.prototype.getYears = (systemDate) => {
      try {
        const anios = [];
        let year = moment(systemDate).format('YYYY');
        year = parseInt(year);
        const finalYear = (year + 12);
        for (let index = year; index <= finalYear; index++) {
          const element = { value: index, label: index };
          anios.push(element);
        }
        return {
          data: anios,
          code: 200
        };
      } catch (error) {
        return {
          message: error,
          code: 400
        };
      }
    };
  }
};
