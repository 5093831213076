export default {
  custom: {
    email: {
      required: 'Email is required',
      email: 'The email is not valid'
    },
    mail: {
      required: 'Email is required',
      email: 'The email is not valid'
    },
    emails: {
      required: 'Email is required',
      email: 'The email is not valid'
    },
    username: {
      required: 'Username is required',
      alpha_spaces: 'Value not allowed, only letters are allowed'
    },
    identification: {
      required: 'The value of the field is required',
      numeric: 'Only numbers are allowed',
      min: 'Enter at least 5 numbers',
      max: 'The maximum value allowed is 20 characters'
    },
    address_checkout: {
      required: 'Address is required',
      numeric: 'Enter numbers only'
    },
    name: {
      required: 'Name is required',
      alpha_spaces: 'Only letters are allowed'
    },
    password: {
      required: 'The password is required',
      min: 'The password must have a minimum of 6 characters'
    },
    password_confirmation: {
      required: 'You are required to confirm the password',
      min: 'The password is too short',
      confirmed: 'Password does not match'
    },
    phone: {
      required: 'The phone number is required',
      numeric: 'Enter numbers only',
      min: 'you must enter a minimum of characters',
      max: 'Ha superado el límite de caracteres'
    },
    phones: {
      required: 'The value of the field is required',
      numeric: 'Enter numbers only'
    },
    address: {
      required: 'Se requiere la dirección de habitación',
      numeric: 'Enter numbers only'
    },
    mes: {
      required: 'The value of the field is required',
      numeric: 'Enter numbers only'
    },
    ano: {
      required: 'The value of the field is required',
      numeric: 'Enter numbers only'
    },
    city: {
      required: 'Se requiere que ingrese la ciudad',
      min: 'Minimum characters not allowed'
    },
    creditCard: {
      required: 'You are required to enter the credit card number',
      error: 'Invalid credit card number'
    },
    bank: {
      required: 'Please select a bank'
    },
    typeDoc: {
      required: 'Please select a document type'
    },
    country: {
      required: 'The value of the field is required'
    },
    identityDocument: {
      required: 'The value of the field is required'
    },
    typePeople: {
      required: 'Please select a type of person'
    },
    people: {
      min_value: 'The minimum value allowed is'
    },
    namePerson: {
      required: 'The name is required'
    },
    nameBuyer: {
      required: 'The name is required'
    },
    emailPerson: {
      required: 'Email is required',
      email: 'The email is not valid'
    },
    emailBuyer: {
      required: 'Email is required',
      email: 'The email is not valid'
    },
    valueGift: {
      required: 'The value is required'
    },
    messageCard: {
      required: 'The message is required'
    },
    dateGift: {
      required: 'The date is mandatory'
    },
    cuota: {
      required: 'The value of the field is required'
    },
    cedula: {
      required: 'The ID is required'
    },
    cardNumber: {
      required: 'Required card number',
      'credit_card': 'Invalid card number'
    },
    cardholderName: {
      required: 'Name is required',
      min: 'you must enter a minimum of characters',
      alpha_spaces: 'Only letters are allowed'
    },
    cardExpirationMonth: {
      required: 'The value of the field is required',
      numeric: 'Enter numbers only'
    },
    cardExpirationYear: {
      required: 'The value of the field is required',
      numeric: 'Enter numbers only'
    },
    securityCode: {
      required: 'The value of the field is required',
      numeric: 'Enter numbers only',
      min: 'Must have a minimum of 3 characters'
    },
    docType: {
      required: 'Please select a document type'
    },
    docNumber: {
      required: 'The value of the field is required',
      numeric: 'Only numbers are allowed'
    },
    name_companion: {
      required: 'Enter a name',
      min: 'Please enter a name greater than 2 letters'
    },
    identification_companion: {
      required: 'Enter identification number',
      numeric: 'Only numbers are allowed',
      min: 'The minimum value allowed is 5 numbers'
    },
    temperature_companion: {
      required: 'Enter temperature',
      numeric: 'Enter numbers only',
      min_value: 'The minimum value is 0'
    },
    phone_companion: {
      required: 'Enter phone number',
      max: 'The maximum value allowed is 14 numbers',
      min: 'The minimum value allowed is 10 numbers',
      numeric: 'Enter numbers only'
    },
    email_companion: {
      required: 'Enter an email',
      email: 'The email is not valid'
    },
    pais_companion: {
      required: 'You must select a country'
    },
    huesped: {
      required: 'You must specify the guest',
      min: 'You must specify the guest'
    },
    clientType: {
      required: 'You must specify the type of customer'
    },
    mediaType: {
      required: 'You must select an option'
    },
    displayName: {
      regex: 'special characters not allowed'
    },
    legalPersonName: {
      required: 'The name of the company is required',
      min: 'Please enter a name greater than 2 letters'
    },
    card: {
      required: 'The card number is required',
      min: 'The card number is not valid'
    }
  }
};
