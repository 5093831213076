<template>
  <b-modal
    :active.sync="active"
    has-modal-card
    :can-cancel="false"
  >
    <div class="modal-card">
      <div class="modal-card-body verifying-transaction">
        <div style="width: 100%; display: flex; align-items: center; gap: 10px; margin: 10px 0px; flex-direction: column;">
          <div class="loader-custom"></div>
          <h4 style="font-weight: bold;">
            ¡NO CIERRES ESTA VENTANA!
          </h4>
        </div>
        <p style="text-align: center;">
          Estamos validando tu pago, esto puede tardar unos minutos, por favor no cierres ni recargues esta página mientras validamos la transacción.
        </p>
      </div>
    </div>
  </b-modal>
</template>
<script>
export default {
  props: [ 'active' ]
};
</script>
<style>
.loader-custom {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  animation: rotate-custom 3s linear infinite;
  border-top: 10px solid #202B47;
  border-bottom: 10px solid #f3f3f3;
  border-left: 10px solid #f3f3f3;
  border-right: 10px solid #f3f3f3;
}

.verifying-transaction {
  max-width: 430px;
  min-height: 280px;
  border-radius: 20px;
  color: #444B57;
  font-family: 'Source Sans Pro', sans-serif;
}

.animation-content {
  max-width: 430px !important;
}

@media screen and (max-width: 500px){
  .verifying-transaction {
    max-width: 360px;
  }
  .animation-content {
    max-width: 360px !important;
  }
}

@keyframes rotate-custom {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

</style>
