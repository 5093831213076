<template>
  <div class="content-language" id="languageChange" :style="getPosition()">
    <b-button
      @click="handleChangeLanguage()"
      class="button-language"
      id="btnLanguage"
      type="button"
    >
      <span :class="{activeLanguage: boolLanguage}">ES</span> / <span :class="{activeLanguage: !boolLanguage}">EN</span>
    </b-button>
  </div>
</template>
<script>
import globalTypes from '@/store/types/global';
export default {
  name: 'Language',
  data () {
    return {
      langSelected: this.$i18n.locale,
      boolLanguage: true
    };
  },
  methods: {
    handleChangeLanguage () {
      this.boolLanguage = !this.boolLanguage;
      this.$i18n.locale = this.boolLanguage ? 'es' : 'en';
      this.$moment.locale(this.boolLanguage ? 'es' : 'en');
      this.$validator.locale = this.boolLanguage ? 'es' : 'en';
      window.localStorage.setItem('lang', this.boolLanguage ? 'es' : 'en');
      this.$store.commit(globalTypes.mutations.setLanguageApp, this.boolLanguage ? 'es' : 'en');
    },
    getPosition () {
      if (this.$route.name === 'branch' && window.innerWidth <= 500) {
        return {
          top: `50px`,
          right: `20px`
        };
      }
      return {
        top: `${this.topBarSize}px`
      };
    }
  }
};
</script>
<style lang="scss">
@import "@/assets/styles/Language/styles.scss";
</style>
