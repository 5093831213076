import namespace from '@/helpers/namespace';

export default namespace('section', {
  getters: [
    'zones'
  ],
  actions: [
    'getZones'
  ],
  mutations: [
    'setZones'
  ]
});
